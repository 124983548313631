import AssetForm from '@/components/Asset/AssetForm/AssetForm.vue'
import AssetBreadcrumbs from '@/components/Asset/AssetBreadcrumbs/AssetBreadcrumbs.vue'
import ErrorMixin from '@/mixins/error.mixin'

export default {
  props: ['id'],
  mixins: [ErrorMixin],
  components: {
    AssetForm,
    AssetBreadcrumbs
  },
  data() {
    return {
      name: null
    }
  },
  methods: {
    setName(v) {
      this.name = v
    }
  },
  created() {
    if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
      this.renderError(404)
    }
  }
}
